@font-face {
  font-family: tungsten-medium;
  src: url(../public/fonts/Tungsten-Medium.woff);
}
.tungsten-medium {
  font-family: tungsten-medium;
}

@font-face {
  font-family: tungsten-semibold;
  src: url(../public/fonts/Tungsten-Semibold.woff);
}
.tungsten-semibold {
  font-family: tungsten-semibold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: tungsten-semibold;
}

p,
div {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1rem;
  color: #003a49;
}

html {
  scroll-behavior: auto !important;
}

@media (max-width: 870px) {
  p,
  div {
    font-size: 1rem;
  }
}

.page-container {
  padding: 50px;
  min-height: 85svh;
}

@media (max-width: 499px) {
  .page-container {
    padding: 50px 20px 50px 20px;
  }
}
/* Start - Home.js responsive page container */
@media (min-width: 870px) {
  .custom-container {
    //display: grid;
    //grid-template-columns: 1fr 1fr;
    //grid-gap: 20px;
    padding: 50px;
    //min-height: 85svh;
    //align-items: center;
  }
}

@media (max-width: 869px) {
  .custom-container {
    //grid-gap: 20px;
    padding: 50px;
    //min-height: 85svh;
    //align-items: center;
  }
}

@media (max-width: 499px) {
  .custom-container {
    //grid-gap: 20px;
    padding: 50px 20px 50px 20px;
    //min-height: 85svh;
    //align-items: center;
  }
}

.home-image-screen {
  max-width: 100%;
  width: 34rem;
}

@media (max-width: 869px) {
  .home-image-screen {
    padding-top: 50px;
  }
}

.chamberLogo {
  width: 225px;
}

@media (max-width: 645px) {
  .chamberLogo {
    width: 150px;
  }
}

@media (max-width: 550px) {
  .chamberLogo {
    width: 125px;
  }
}

/* End - Home.js responsive page container */

/* Home.js responsive column*/
@media (min-width: 869px) {
  .text-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

/* Header.css */
.header {
  background-color: #003a49;
  padding: 20px 50px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Tungsten-medium";
  font-size: 1.2rem;
  letter-spacing: 0.5px;
}

@media (max-width: 499px) {
  .header {
    padding: 20px 20px;
  }
}

.header h1 {
  margin: 0;
}

.header nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.header nav li {
  margin-right: 35px;
}

.header nav a {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.header nav a.active {
  background-color: rgb(122, 204, 0);
  padding: 10px;
  border-radius: 5px;
  color: #003a49;
}

/* Hamburger menu styles */
.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 35px;
  color: rgb(122, 204, 0);
}

/* Media query for screens with a width of 768 pixels or lower */
@media (max-width: 768px) {
  .header nav ul {
    display: none; /* Initially hide the mobile menu */
    flex-direction: column;
    position: absolute;
    top: 99px;
    left: 0;
    width: 100%;
    background-color: #003a49;
  }

  .header nav ul.show-mobile-menu {
    display: flex; /* Show mobile menu when needed */
  }

  .header nav li {
    margin-right: 0;
    margin-bottom: 15px;
    text-align: center;
  }

  .hamburger-menu {
    display: block;
  }

  .mobile-menu {
    display: none;
    z-index: 2;
  }

  .mobile-menu-open .hamburger-menu {
    color: rgb(122, 204, 0);
  }

  .mobile-menu-open .header nav ul {
    display: flex; /* Show the mobile menu when it's open */
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background-color: #003a49;
  color: white;
}

@media (max-width: 499px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
    background-color: #003a49;
    color: white;
  }
}

.skipSpaceFooterLogo {
  width: 90px;
}

@media (max-width: 499px) {
  .skipSpaceFooterLogo {
    padding-bottom: 25px;
  }
}

@media (max-width: 499px) {
  .privacy-policy-text {
    padding-bottom: 5px;
  }
}

.footer-text {
  text-align: right;
}

@media (max-width: 499px) {
  .footer-text {
    text-align: center;
    padding-top: 20px;
  }
}

.svg-inline--fa {
  vertical-align: middle;
}

/* Start - password login text mobile responsive*/
.password-login-text {
  padding-bottom: 25px;
  color: white;
  font-size: 2rem;
}
@media (max-width: 499px) {
  .password-login-text {
    font-size: 1.2rem;
  }
}
/* End - password login text mobile responsive*/

/* Start - form input fields adjusted for register.js and signin.js*/
.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #003a49;
  border-radius: 5px;
  outline: none;
}

.input-field:focus {
  border: 1px solid rgb(122, 204, 0);
  outline: none;
}
/* End - form input fields adjusted for register.js and signin.js*/

/* Start - form input fields adjusted for contactForm.js*/
.contact-input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #003a49;
  border-radius: 5px;
  outline: none;
}

.contact-input-field:focus {
  border: 1px solid rgb(122, 204, 0);
  outline: none;
}
/* End - form input fields adjusted for contactForm.js*/

/* Define the default input style */
.registerInputStyle {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #003a49;
  border-radius: 5px;
  outline: none;
}

/* Define the input focus style */
.registerInputStyle:focus {
  border-color: rgb(122, 204, 0); /* Change this to the desired focus color */
  outline: none;
}

/* Start - Hamburger Menu Styles */
.mobile-menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
}

@media (max-width: 768px) {
  .mobile-menu-icon {
    display: flex;
  }
}

@media (max-width: 768px) {
  .nav ul {
    flex-direction: column;
    border: solid 2px rgb(122, 204, 0);
    padding: 10px;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .nav ul li {
    padding: 10px;
  }
}

.mobile-menu-icon .bar {
  width: 30px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: 0.4s;
}

.logo {
  width: 90px;
}

/* End - Hamburger Menu Styles */

/* Start - Our Impact page made responsive */
@media (min-width: 1320px) {
  .ourImpactColumnStyle {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 1320px) {
  .headlineFactsWidth {
    width: 40%;
  }
}

@media (min-width: 1320px) {
  .dividerStyle {
    border-left: 2px solid black;
    margin: 0 30px;
  }
}

@media (min-width: 625px) {
  .positiveImpactColumnStyle {
    display: flex;
  }
}

@media (min-width: 1320px) {
  .page-padding {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 1320px) {
  .positive-impact-padding {
    padding-top: 25px;
  }
}

@media (max-width: 600px) {
  #root
    > div
    > div
    > div
    > div.ourImpactColumnStyle.page-padding
    > div.headlineFactsWidth
    > ul
    > li:nth-child(2) {
    padding: 0px 0px 25px !important;
  }
}
/* End - Our Impact page made responsive */

/* Start - How It Works page */

.first-column-style {
  flex: 1 1 auto;
  width: calc(18% - 15px);
  text-align: center;
}

@media (max-width: 875px) {
  .first-column-style {
    flex: auto;
    width: calc(50% - 15px);
    text-align: "center";
  }
}

@media (max-width: 875px) {
  .voucher-confirmed {
    max-width: 50%;
  }
}

@media (max-width: 875px) {
  .hidden-columns {
    display: none;
  }
}
/* End - How It Works page */

/* Start - Forgot password page */
.forgot-password-page-container {
  padding: 50px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
}
/* End - Forgot password page */

/* Start - Council Portal dashboard */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 20px;
}

.portal-button {
  padding: 15px 30px;
  margin: 10px 0;
  background-color: #003a49;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 20rem;
  max-width: 100%;
}
@media (max-width: 350px) {
  .portal-button {
    width: 18rem;
  }
}

.portal-button:hover {
  background-color: #014a5d;
}

.portal-dashboard-main {
  display: grid;
  align-content: center;
  min-height: 85svh;
}

@media (max-width: 499px) {
  .portal-dashboard-main {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.welcome-council {
  text-align: center;
}

@media (min-width: 499px) {
  .welcome-council {
    padding-top: 50px;
  }
}

/* End - Council Portal dashboard */

/* Text decoration underline hover colour  */

.text-underline {
  text-decoration-color: #003a49 !important;
}

.text-underline:hover {
  text-decoration-color: rgb(122, 204, 0) !important;
}

/* Start - Council Portal - Council Account details */

.back-to-dashboard {
  align-self: flex-start;
}

.council-acc-details-page-container {
  padding: 50px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
}
@media (max-width: 499px) {
  .council-acc-details-page-container {
    padding: 50px 20px 50px 20px;
  }
}

.delete-account-text {
  color: red;
  cursor: pointer;
}

.delete-account-text:hover {
  text-decoration: underline;
}

.delete-confirm-button-yes {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
  margin-right: 12.5px;
}

.delete-confirm-button-no {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
  margin-left: 12.5px;
}

/*
@media (max-width: 350px) {
  .council-details-email-mobile{
    font-size: 0.9rem;
  }}
  */

/* End - Council Portal - Council Account details */

/* Start - Council Portal - change profile name */

.back-to-dashboard {
  align-self: flex-start;
}

.change-email-page-container {
  padding: 50px;
  flex: none;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
}

@media (max-width: 768px) {
  .change-email-page-container {
    padding: 50px 20px 50px 20px;
  }
}
/* End - Council Portal - change profile name */

/* Start - Council Portal - change password */

.back-to-dashboard {
  align-self: flex-start;
}

.change-password-page-container {
  padding: 50px;
  flex: none;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
}
@media (max-width: 768px) {
  .change-password-page-container {
    padding: 50px 20px 50px 20px;
  }
}

/* End - Council Portal - change password */

/* Start - Council Portal - change email */

.back-to-dashboard {
  align-self: flex-start;
}

.change-profile-name-page-container {
  padding: 50px;
  flex: none;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
}
@media (max-width: 768px) {
  .change-profile-name-page-container {
    padding: 50px 20px 50px 20px;
  }
}

/* End - Council Portal - change email */

/* Start - Council Portal - Link skip company page */

.link-skip-company-page-container {
  padding: 50px;
  flex: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
}
@media (max-width: 768px) {
  .link-skip-company-page-container {
    padding: 50px 20px 50px 20px;
  }
}

.link-skip-company {
  text-align: center;
}

.back-to-dashboard {
  text-align: left;
}

.select-container {
  text-align: center;
  padding-top: 25px;
}

.skip-company-details {
  display: grid;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.approval-checkbox {
  display: flex;
}

.save-changes-button {
  padding-top: 50px;
  text-align: center;
}

.button {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
}

/* End - Council Portal - Link skip company page */

/* Start - Council Portal - View skip company details */

.view-skip-company-details-page-container {
  padding: 50px;
  min-height: 85svh;
}

.vouchers-issued {
  border: 2px solid rgb(0, 58, 73);
  border-radius: 5px;
  padding: 20px;
  display: inline-block;
  margin-top: 25px;
}

@media (max-width: 430px) {
  .vouchers-issued {
    display: flex;
    justify-content: center;
    margin-right: 0px;
  }
}

.vouchers-used {
  border: 2px solid rgb(0, 58, 73);
  border-radius: 5px;
  padding: 20px 28px;
  display: inline-block;
}

@media (max-width: 430px) {
  .vouchers-used {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  .view-skip-company-details-page-container {
    padding: 50px 20px 50px 20px;
  }
}

.company-section {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #003a49;
  padding-bottom: 25px;
  padding-top: 25px;
  align-items: center;
}

@media (max-width: 600px) {
  .company-section {
    flex-wrap: wrap;
  }
}

.last-section {
  border-bottom: none; /* Remove the border for the last element */
}

.border-box {
  border: 1px solid #003a49;
  padding: 25px;
  text-align: center;
}

@media (max-width: 600px) {
  .border-box {
    margin-top: 15px;
  }
}

/* End - Council Portal - Link skip company details */

.ReactModal__Overlay {
  position: fixed !important;
  inset: 0px !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 2;
}

.ReactModal__Content {
  position: fixed !important;
  inset: 0px !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  text-align: center;
}

.reauth-submit-button {
  padding: 10px 30px;
  margin: 25px 0px 0px 0px;
  background-color: #003a49;
  color: rgb(122, 204, 0);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reauth-cancel-button {
  padding: 10px 30px;
  margin: 10px 0;
  background-color: #003a49;
  color: white;
  border: solid 1px black;
  border-radius: 5px;
  cursor: pointer;
}

.sign-out-confirm-button-yes {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
  margin-right: 12.5px;
}

.sign-out-confirm-button-no {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
  margin-left: 12.5px;
}

select {
  word-wrap: normal;
  border: solid 1px #003a49;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 368l192-192H64z"/></svg>'); /* Font Awesome chevron-down icon */
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 14px 14px !important;
  outline: none !important;
  color: #000000 !important;
  background-color: #ffffff !important;
}

/* Hide the default arrow in Firefox */
select::-ms-expand {
  display: none;
}

.contact-page-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 85svh;
  justify-content: center;
}
@media (max-width: 499px) {
  .contact-page-container {
    padding: 50px 20px 50px 20px;
  }
}

.privacy-page-container {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: left;
  min-height: 85svh;
  justify-content: center;
}
@media (max-width: 499px) {
  .privacy-page-container {
    padding: 50px 20px 50px 20px;
  }
}

.delete-site-button-yes {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
  margin-right: 3px;
}
.delete-site-button-no {
  border-radius: 5px;
  padding: 10px 30px;
  background-color: #003a49;
  color: #ffffff;
  font-family: "tungsten-medium";
  outline: none;
  border: solid 1px #003a49;
  margin-left: 3px;
}

.delete-account-modal {
  padding: 0px 20px 0px 20px;
}

.kirk {
  -webkit-text-stroke: thin !important;
}

.bold-privacy {
  -webkit-text-stroke: thin !important;
}

.bold-sub-heading-privacy {
  -webkit-text-stroke: 1px !important;
}

.bold-italic-privacy {
  -webkit-text-stroke: thin !important;
  font-style: italic !important;
}

.bold-underline-privacy {
  -webkit-text-stroke: thin !important;
  text-decoration: underline !important;
}

.privacy-policy-text {
  font-size: 12px;
  color: white;
  text-decoration: none;
}

.privacy-policy-text:hover {
  text-decoration: underline;
}

.download-links {
  text-decoration-color: #003a49 !important;
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-skip-ink: none;
  font-weight: bold;
  color: #003a49;
}

.download-links:hover {
  text-decoration-color: rgb(122, 204, 0) !important;
}

/* SKIPSPACE INFOGRAPHIC */

/* desktop view */
.video-grid {
  display: grid;
  grid-template-columns: repeat(
    5,
    1fr
  ); /* 3 videos and 2 separators in a row */
  grid-template-rows: auto auto auto; /* Two video rows and one line row */
  padding: 50px;
  padding-bottom: 0;
  padding-right: 14%;
  align-items: center; /* Align items in the center for a consistent look */
  row-gap: 2%;
}

.video-container {
  width: 120%;
  position: relative;
  margin-top: 2%;
}

.video-container img {
  position: absolute;
  height: auto;
  z-index: 1;
}

.video-container video {
  width: 100%;
  height: auto;
}

.step1 {
  top: 20%;
  right: -11%;
  width: 36%;
}

.step2 {
  top: 20%;
  right: -19%;
  width: 43%;
}

.step3 {
  top: 57%;
  right: -19%;
  width: 40%;
}

.step4 {
  top: 24%;
  right: -9%;
  width: 31%;
}

.step5 {
  top: 58%;
  right: -18%;
  width: 37%;
}

.step6 {
  top: 11%;
  right: -40%;
  width: 61%;
}

.separator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 86%;
}

.dashed-line {
  width: 100%;
  border-top: 1px dashed #003a49;
  margin: 0;
  position: relative;
}

.dashed-line::after {
  content: "";
  position: absolute;
  right: 0; /* Position the dot at the end of the line */
  top: -3px; /* Align the dot vertically with the dashed line */
  width: 5px; /* Diameter of the dot */
  height: 5px; /* Diameter of the dot */
  background-color: #003a49; /* Dot color */
  border-radius: 50%; /* Make it a circle */
}

/* New CSS for the vertical dashed line */
.vertical-line-container {
  grid-column: 1 / -1; /* Span all columns */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Allow positioning the line */
  z-index: 1;
}

.vertical-dashed-line {
  position: absolute; /* Position relative to the grid */
  width: calc(100% - 19%); /* Reduced width to leave some space on both ends */
  border-top: 1px dashed #003a49;
  margin: 0;
  left: 11%; /* Center the line by moving it towards the middle */
}

.start-vertical-dashed-line {
  position: absolute;
  width: 3%;
  border-top: 1px dashed #003a49;
  margin: 0;
  transform: rotate(90deg);
  transform-origin: left;
  left: 11%;
}

.start-vertical-dashed-line::after {
  content: "";
  position: absolute;
  right: 0; /* Position the dot at the end of the line */
  top: -3px; /* Align the dot vertically with the dashed line */
  width: 5px; /* Diameter of the dot */
  height: 5px; /* Diameter of the dot */
  background-color: #003a49; /* Dot color */
  border-radius: 50%; /* Make it a circle */
}

.end-vertical-dashed-line {
  position: absolute;
  width: 3%;
  border-top: 1px dashed #003a49;
  margin: 0;
  transform: rotate(90deg);
  transform-origin: right;
  right: 8%;
}

/* Mobile layout */
@media (max-width: 768px) {
  .video-grid {
    display: none; /* Hide the desktop grid on mobile */
  }

  /* Mobile-specific grid */
  .mobile-video-grid {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 0;
    padding-right: 16%;
    align-items: center;
  }

  .video-container {
    width: 75%;
  }

  /* Mobile-specific separator styling */
  .mobile-separator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto;
    width: 100%;
    position: relative;
  }

  .mobile-vertical-dashed-line {
    height: 50px;
    border-left: 1px dashed #003a49;
    position: relative;
  }

  .mobile-vertical-dashed-line::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3px;
    width: 5px;
    height: 5px;
    background-color: #003a49;
    border-radius: 50%;
  }
}

.homepage-container {
  display: flex;
  align-items: center;
  gap: 20px;

  margin: 0 auto;
}

.homepage-text-column {
  flex: 1;
  max-width: 60%;
}

.image-column {
  flex: 1;
  max-width: 40%;
}

/* Ensure image stays the same size */
.responsive-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive Design for smaller screens */
@media (max-width: 1400px) {
  .homepage-container {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .homepage-text-column {
    max-width: 100%;
  }

  .image-column {
    max-width: 100%;
    text-align: center;
  }

  .responsive-image {
    max-width: 100%; /* Adjust size on smaller screens */
    margin-bottom:40px;
    border-radius: 8px;
  }
}

/* Announcement Section - Two Columns */
.announcement-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 40px 0;
}

/* Left & Right Columns - Default 50% width */
.column {
  width: 50%;
}

/* Image Styling */
.awards-logo {
  max-width: 300px;
  height: auto;
  display: block;
  margin: 0 auto; /* Centers the image */
}

/* Video Container Styling */
.talktv-video-container {
  width: 100%;
}

/* Divider Styling */
.section-divider {
  width: 100%;
  margin: 40px auto;
  height: 1px;
  background-color: #003A49;
  border: 0;
}

/* Responsive Design - Stack on Mobile */
@media (max-width: 768px) {
  .announcement-section {
    flex-direction: column;
    text-align: center;
  }

  .column {
    width: 100%;
  }

  .talktv-video-container video {
    width: 100%;
    height: auto;
  }
}


@media (max-width: 1000px) {
  .talktv-video-container  {
    width: 100%;
    height: auto;
  }}


.rolling-banner {
  width: 100%;
  background: #f8c100; /* Gold-like background */
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 60px; /* Adjust to fit text */
  white-space: nowrap;
}

.rolling-content {
  display: flex;
  align-items: center;
  gap: 40px;
  min-width: 200%; /* Ensures enough content to prevent gaps */
  animation: scrollBanner 20s linear infinite;
}

.rolling-content span {
  display: flex;
  align-items: center;
  gap: 20px;
  white-space: nowrap;
}

.rolling-text {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  font-family: "tungsten-medium";
  letter-spacing: 1.1px;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

@keyframes scrollBanner {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

